
import { defineComponent, PropType } from "vue";

export type CTabItem = { label: string; icon: string; component?: string; to?: any };

const CTabs = defineComponent({
  name: "CTabs",
  props: {
    tabs: { type: Object as PropType<CTabItem[]> },
    current: { type: Object as PropType<CTabItem> },
  },
});

export default CTabs;
