
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";

import CSpinner from "@/components/spinner.vue";
import CAppointmentCard from "@/views/appointment/card.vue";
import CAppointmentDetailCard from "@/views/appointment/detail-card.vue";
import ObTabs from "@/components/tabs.vue";

import { formatDate, formatHours, formatPhone, addMoneyMask } from "@/utils/formatters";
import { Appointment } from "@/store/user/appointment/types";
import { useRoute, useRouter } from "vue-router";
import { useFilters } from "@/utils/mixins";
import { getHistoricStatus } from "@/utils";

const VAppointment = defineComponent({
  name: "VAppointment",
  components: { CSpinner, CAppointmentCard, CAppointmentDetailCard, ObTabs },
  setup() {
    useHead({ title: "Agendamentos | obmed" });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilters(["ie_status"]);

    const appointments = computed(() => store.state.user.appointment.list);
    const groupedAppointments = computed(() => {
      const ie_status = currentTab.value?.to?.query?.ie_status;
      if (ie_status === "A") return store.getters.currentAppointmentsGrouped;
      if (ie_status === "NA") return store.getters.pastAppointmentsGrouped;

      return store.getters.appointmentsGrouped;
    });
    const loading = reactive({ list: false, print: 0 });

    const tabs = [
      {
        label: "Agendados",
        icon: "fas fa-calendar-alt",
        to: { name: "appointment", query: { ie_status: "A" } },
      },
      {
        label: "Atendidos",
        icon: "fas fa-calendar-check",
        to: { name: "appointment", query: { ie_status: "EX" } },
      },
      {
        label: "Não Atendidos",
        icon: "fas fa-calendar-times",
        to: { name: "appointment", query: { ie_status: "NA" } },
      },
      {
        label: "Pendentes de Pagamento",
        icon: "fas fa-hand-holding-dollar",
        to: { name: "appointment", query: { ie_status: "R" } },
      },
    ];
    const currentTab = ref(
      tabs.find((item) => item.to.query.ie_status === route.query.ie_status?.toString()) || tabs[0]
    );

    function handleReschedule(appointment: Appointment) {
      router.push({
        name: "appointment-reschedule",
        params: { _id: appointment.id },
        query: { sy: appointment.cd_especialidade?.id, et: appointment.cd_estabelecimento?.id },
      });
    }

    async function printElectronicGuide(appointment: Appointment) {
      const _id = appointment.guiaeletronica_set[0]?.id;
      const response = await store.dispatch("getElectronicGuide", { _id });
      window.open(URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" })));
    }

    async function downloadElectronicGuide(appointment: Appointment) {
      loading.print = appointment.id;

      const _id = appointment.guiaeletronica_set[0]?.id;
      const response = await store.dispatch("getElectronicGuide", { _id });

      const linkEl = document.createElement("a");
      linkEl.href = URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" }));
      linkEl.setAttribute("download", `guia_${_id}.pdf`);
      linkEl.click();

      loading.print = 0;
    }

    async function getAppointments() {
      loading.list = true;
      await store.dispatch("searchAppointments", {
        search: { ie_status: route.query.ie_status?.toString() },
        isPrimary: true,
      });
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "appointment-filter", query: route.query });
    else getAppointments();

    watch(
      () => route.query.ie_status,
      () => {
        router.replace({ ...route, query: { ie_status: route.query.ie_status } });
        getAppointments();
      }
    );

    return {
      ...{ appointments, groupedAppointments, loading, hasFilters, tabs, currentTab },
      ...{ printElectronicGuide, downloadElectronicGuide, handleReschedule },
      ...{ formatDate, formatHours, formatPhone, addMoneyMask, getHistoricStatus },
    };
  },
});

export default VAppointment;
